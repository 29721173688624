import moment from "moment";

export default {
    methods: {
        timeConvert(value) {
            return moment.unix(value).format("DD.MM.YYYY HH:mm");
        },
        timeConvertWithoutHours(value) {
            return moment.unix(value).format("YYYY-MM-DD");
        },
        timeConvertTender(value) {
            return moment.unix(value).format("DD.MM.YYYY");
        },
        timeConvertTenderV2(value) {
            return moment(`${value}`, 'DD.MM.YYYY"').toString()
        },
        timeUnix(value) {
            return moment(value).unix();
        },
        timeUnixValid(value) {
            return moment.unix(value).isValid();
        }
    }
}